import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import { useFacilityData } from 'hooks/useFetch';
import {
    Card as MuiCard,
    CardContent as MuiCardContent,
    CardHeader,
    Table,
    TableBody,
    TableCell as MuiTableCell,
    TableHead,
    TableRow as MuiTableRow,
    Backdrop,
    Typography,
} from '@mui/material';
import { Box, spacing } from '@mui/system';

import { ThemeProps } from '../../../types/theme';
import { useAtomValue } from 'jotai';
import { greenBondIdAtom } from '../../../jotai/Atoms';
import { getGreenBondConfig } from 'greenBondConfig';

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
    &:last-child {
        padding-bottom: ${(props) => props.theme.spacing(2)};
    }
`;

const ChartWrapper = styled.div`
    height: 195px;
    position: relative;
`;

const TableRow = styled(MuiTableRow)`
    height: 42px;

    &:last-child th,
    &:last-child td {
        border-bottom: 0;
    }
`;

const TableCell = styled(MuiTableCell)`
    padding-top: 0;
    padding-bottom: 0;
`;

const SummaryFunduseChart = ({ theme }: ThemeProps) => {
    // APIから取得した各発電所データ
    const greenBondId = useAtomValue(greenBondIdAtom);
    const config = getGreenBondConfig(greenBondId);
    const fetchdata = useFacilityData(greenBondId);
    let sum = 0;
    let usedSum = 0;
    let usedRatio = 0;
    const labelitem: any = [];
    const dataitem: any = [];
    const dataUsedItem: any = [];
    const tabledata: any = [];
    if (fetchdata) {
        fetchdata.forEach((item: any) => {
            sum = sum + item['developmentAmount'];
            usedSum = usedSum + item['developmentAmountUsed'];
            labelitem.push(item['facilityName']);
            dataitem.push(item['developmentAmount']);
            dataUsedItem.push(item['developmentAmountUsed']);
            tabledata.push({
                labels: item['facilityName'],
                category: item['powerCategory'],
                amount: (item['developmentAmount'] / 1000000).toFixed(1),
                usedAmount: (item['developmentAmountUsed'] / 1000000).toFixed(1),
            });
        });
        usedRatio =
            dataUsedItem.reduce(function (sum: any, element: any) {
                return sum + element;
            }, 0) /
            dataitem.reduce(function (sum: any, element: any) {
                return sum + element;
            }, 0);
        sum /= 1000000;
        usedSum /= 1000000;
    }

    const data = {
        labels: labelitem,
        datasets: [
            {
                data: dataitem,
                backgroundColor: [
                    theme.palette.secondary.main,
                    theme.palette.secondary.red,
                    theme.palette.secondary.orange,
                    theme.palette.secondary.green,
                ],
                borderWidth: 1,
                borderColor: theme.palette.background.paper,
                tooltip: {
                    callbacks: {
                        label: (context: { label: string; formattedValue: number }) => {
                            const label = context.label || '';
                            const value = context.formattedValue || 0;
                            return `${label}: ${value}円`; // 単位を明示
                        },
                    },
                },
            },
            {
                data: dataUsedItem,
                backgroundColor: [
                    theme.palette.primary.dark,
                    theme.palette.secondary.red,
                    theme.palette.secondary.orange,
                    theme.palette.secondary.green,
                ],
                borderWidth: 1,
                borderColor: theme.palette.background.paper,
                circumference: usedRatio * 360,
                tooltip: {
                    callbacks: {
                        label: (context: { label: string; formattedValue: number }) => {
                            const label = context.label || '';
                            const value = context.formattedValue || 0;
                            return `充当済 ${label}: ${value}円`; // 単位を明示
                        },
                    },
                },
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        cutout: '80%',
    };

    return config?.greenType == 'powerPlant' ? (
        <Card mb={6} sx={{ position: 'relative' }}>
            {config.cashIn ? (
                <Backdrop
                    open={true}
                    component={CardContent}
                    sx={{ zIndex: theme.zIndex.drawer - 1, position: 'absolute' }}
                >
                    <Box borderColor="#FFFFFF">
                        <Typography color="#FFFFFF" fontSize={24}>
                            償還済み
                        </Typography>
                    </Box>
                </Backdrop>
            ) : (
                <></>
            )}
            <CardHeader title="資金使途" />
            <CardContent>
                <ChartWrapper>
                    <Doughnut data={data} options={options} />
                </ChartWrapper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">発電施設名称</TableCell>
                            <TableCell align="left">発電種別</TableCell>
                            <TableCell align="left">金額</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tabledata.map((row: any) => {
                            return (
                                <TableRow key={row.labels}>
                                    <TableCell component="th" scope="row">
                                        {row.labels}
                                    </TableCell>
                                    <TableCell align="left">{row.category}発電</TableCell>
                                    <TableCell align="left">{row.amount}百万円</TableCell>
                                </TableRow>
                            );
                        })}
                        <TableRow>
                            <TableCell component="th" scope="row">
                                合計
                            </TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">{sum}百万円</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    ) : config?.greenType == 'greenBuilding' ? (
        <Card mb={6} sx={{ position: 'relative' }}>
            {config.cashIn ? (
                <Backdrop
                    open={true}
                    component={CardContent}
                    sx={{ zIndex: theme.zIndex.drawer - 1, position: 'absolute' }}
                >
                    <Box borderColor="#FFFFFF">
                        <Typography color="#FFFFFF" fontSize={24}>
                            償還済み
                        </Typography>
                    </Box>
                </Backdrop>
            ) : (
                <></>
            )}
            <CardHeader title="資金使途・充当状況" />

            <CardContent>
                <ChartWrapper>
                    <Doughnut data={data} options={options} />
                </ChartWrapper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">施設名称</TableCell>
                            <TableCell align="left">金額</TableCell>
                            <TableCell align="left">充当済金額</TableCell>
                            <TableCell align="left">リファイナンス割合</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tabledata.map((row: any) => {
                            return (
                                <TableRow key={row.labels}>
                                    <TableCell component="th" scope="row">
                                        {row.labels}
                                    </TableCell>
                                    <TableCell align="left">{row.amount}百万円</TableCell>
                                    <TableCell align="left">{row.usedAmount}百万円</TableCell>
                                    <TableCell align="left">{(row.usedAmount / row.amount) * 100}%</TableCell>
                                </TableRow>
                            );
                        })}
                        <TableRow>
                            <TableCell component="th" scope="row">
                                合計
                            </TableCell>
                            <TableCell align="left">{sum}百万円</TableCell>
                            <TableCell align="left">{usedSum}百万円</TableCell>
                            <TableCell align="left">{(usedSum / sum) * 100}%</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    ) : (
        <></>
    );
};

export default withTheme(SummaryFunduseChart);
